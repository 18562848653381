export default {
  title: "Lecture Noted",
  refName: "lecture_noted",
  tagline: "Autogenerated notes from YouTube lectures",
  workType: "Hackathon",
  types: ["Web App Development", "GPT-3", "UI Design"],
  typesShort: ["Web", "GPT-3", "UI"],
  color: "#EBB706",
  previewStyle: "preview4",
  details: [
    {
      header: "Event",
      body: "HooHacks 2021"
    },
    {
      header: "Prize",
      body: "Best Educational Hack"
    },
    {
      header: "Date",
      body: "March 2021"
    }
  ],
  links: [
    {
      text: "Visit Live Website",
      url: "http://vinaybhaip.com/Lecture-Noted/"
    },
    {
      text: "Watch Demo",
      url: "https://youtu.be/pzo1vDwjNiw?t=3"
    },
    {
      text: "View on GitHub",
      url: "https://github.com/Pranav-Wadhwa/Lecture-Noted"
    }
  ],
  tools: ["OpenAI GPT-3", "HTML/CSS", "JavaScript", "Firebase", "Heroku"],
  descriptions: [
    {
      header: "The Problem",
      body: `
            During the pandemic, many students turned to online lectures on YouTube, from both their teachers and other educators, to learn material for their courses. However, for many of these students including myself, it's difficult to take notes while watching as it prevents you from focusing 100% of your attention on the content.
            `
    },
    {
      header: "Our Solution",
      body: `At HooHacks 2021, my team and I developed Lecture Noted: an online tool that generates smart notes that enrich your learning experience from lecture videos on Youtube. It provides concise bullet points that cross reference to the video as well as graphics that are related to the topic. Lecture Noted uses a <b>GPT-3 language model</b> from <a href="https://openai.com/">OpenAI</a> to summarize complex lecture videos. Overall, this assistant allows students to fully focus on their lectures and still have notes available to reference after.
            `
    },
    {
      header: "My Role",
      body: `
            I designed and built the frontend of Lecture Noted while my teammates worked on making backend calls to OpenAi on a Python Flask server. Using HTML, CSS and Vanilla JS, I was able to implement my sketch designs onto the website within the 24 hours. The frontend is deployed on <a href="http://vinaybhaip.com/Lecture-Noted/">lecturenoted.tech</a> using Firebase hosting, and the backend is on a Heroku server.
            `
    }
  ]
};
