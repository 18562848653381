<template>
  <div class="Nav">
    <a class="Nav-link" href="/" target="_self">Pranav Wadhwa</a>
  </div>
</template>

<script>
export default {
  name: "Nav"
};
</script>

<style lang="scss">
@import "./Nav.scss";
</style>
